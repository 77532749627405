<template>
  <div class="container attendance-warp flex-column">
    <div class="common-head">
      <img
        :src="require('@/assets/image/personbg.png')"
        alt=""
        class="bg-img"
      />
      <img class="img-title" :src="require('@/assets/image/kqdk.png')" alt="" />
      <div class="head-bottom-warp">
        <div
          class="align-center head-item"
          v-for="(item, index) in list"
          :key="index"
          :class="activIndex == index ? 'blue' : 'black'"
          @click="handleClick(index)"
        >
          <div class="bg-blur"></div>
          <span>{{ item }}</span>
        </div>
      </div>
    </div>
    <template v-if="activIndex === 0">
      <div class="date-bottom-warp">
        <div class="date-item d-around blue">
          <div class="bg-blur"></div>
          <div class="df-center">
            <img
              class="img-yuandian"
              :src="require('@/assets/image/red.png')"
              alt=""
            />
            <span class="f-s12 p-l6">上班：{{formData.in_count === 0 ? '未打卡':'已打卡'}}</span>
          </div>
          <div class="df-center">
            <img
              class="img-yuandian"
              :src="require('@/assets/image/blue.png')"
              alt=""
            />
            <span class="f-s12 p-l6">下班：{{formData.out_count === 0 ? '未打卡':'已打卡'}}</span>
          </div>
        </div>
      </div>
      <div class="flexone">
        <div v-if="!leaveShow" class="clock-box">
          <div class="clock-content" v-if="!!formData.in_count && !!formData.out_count">
            <p class="f-s24 c-fff">打卡成功</p>
          </div>
          <div class="clock-content" @click="handleDk" v-else>
            <p class="f-s24 c-fff">{{clockStatus === 1 ? '上班打卡':'下班打卡'}}</p>
            <p class="f-s20 c-fff">{{ curTimeStr }}</p>
          </div>
        </div>
        <div class="ask-leaveBox" v-else>
          <p class="leave-title">请假</p>
          <van-dropdown-menu active-color="#3a86ff" class="drop-menu">
            <van-dropdown-item v-model="ruleform.value" :options="oneList">
            </van-dropdown-item>
          </van-dropdown-menu>
          <van-field
            v-model="ruleform.levelOne"
            autosize
            type="textarea"
            placeholder="说明"
            class="leave-textarea"
          />
          <p class="leave-flexButton">
            <span class="leave-button leave-button-border" @click="handleCancel"
              >取消</span
            >
            <span
              class="leave-button c-blue leave-button-borderight"
              @click="handleSure"
              >确认</span
            >
          </p>
        </div>
        <template  v-if="!(!!formData.in_count && !!formData.out_count)">
          <div class="clock-addr" >
            <img
              class="img-addr"
              :src="require('@/assets/image/green.png')"
              alt=""
            />
            <span class="f-s12 p-l10">{{ address }}</span>
          </div>
          <div class="warp-btn">
            <van-button type="info" color="rgb(38, 120, 255)" @click="changeMap"
              >点 击 更 改 位 置 信 息</van-button
            >
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <attendanceStatistics></attendanceStatistics>
    </template>
  </div>
</template>

<script>
import attendanceStatistics from "./attendanceStatistics.vue";
import mixin from "@/mixin/dateMixin";
import httpService from "@/plugins/http";
import { Toast } from 'vant';
export default {
  components: {
    attendanceStatistics,
  },
  computed:{
    clockStatus(){
      let flag = 1; // 1上班打卡 0 下班
      // if(!this.isMorning || this.formData.in_count === 1) {
      if(this.formData.in_count === 1){
        flag = 0
      }
      return flag 
    }
  },
  mixins: [mixin],
  data() {
    return {
      activIndex: 0,
      list: ["考勤打卡", "考勤统计"],
      show: false,
      dataIndex: 0,
      dateList: ["日", "周", "月"],
      leaveShow: false, //是否请假
      ruleform: {
        value: 0,
      },
      oneList: [
        { text: "事假", value: 0 },
        { text: "新款商品", value: 1 },
        { text: "活动商品", value: 2 },
      ],
      curTime: "", // 记录当前时间
      curTimeStr: "", // 当前时间展示字符串
      times: "", // 记录定时器
      // isMorning:true, // 默认是早上
      address: "", // 当前地址
      curlatitude: "", // 当前地址经度
      curlongitude: "", // 当前地址维度
      formData:{},
    };
  },
  created() {
    this.curTimeStr = this.handleFormatDate(new Date());
    this.times = setInterval(() => {
      this.curTimeStr = this.handleFormatDate(new Date());
      // this.getIsMorning();
    }, 1000);
  },
  mounted() {
    this.getlocation();
    this.checkTodayTime();
  },
  methods: {
    // getIsMorning() {
    //   let date=(new Date()).getHours();
    //   if (date>=0&&date<12){
    //     this.isMorning = true
    //   } else {
    //     this.isMorning = false
    //   }
    // },
    async getInit(params) {
      try {
        this.$store.commit("showLoading");
        const res = await httpService.post("/api/staff/clockio/stats", {
          begin: params.begin,
          end: params.end,
        });
        console.log(res, "treer");
        if (res.code === 0) {
          this.formData = res.data || {};
          console.log(this.formData,'this.formDatathis.formData')
        } else {
          this.$store.commit("hideLoading");
        }
      } catch (error) {
        console.log(error, "error");
      } finally {
        this.$store.commit("hideLoading");
      }
    },
    getCurAddress({ lng, lat }) {
      // const queryLng = this.$route.query.lng;
      // const queryLat = this.$route.query.lat;
      // if (queryLng && queryLat) {
      //   var point = new BMap.Point(queryLng, queryLat);
      // } else {
        var point = new BMap.Point(lng, lat);
      // }
      var gc = new BMap.Geocoder();
      let _this = this;
      gc.getLocation(point, function (rs) {
        _this.address = rs.address;
      });
    },
    getlocation() {
      try {
        const {lng,lat} = this.$route.query;
        if (lng) {
          this.curlatitude = lat;
          this.curlongitude = lng;
          this.getCurAddress({ lng: this.curlongitude, lat: this.curlatitude });
        } else {
          const geolocation = new BMap.Geolocation();
            geolocation.getCurrentPosition((r) => {
              this.curlatitude = r.latitude;
              this.curlongitude = r.longitude;
              this.getCurAddress({ lng: this.curlongitude, lat: this.curlatitude });
            });
          }
      
      } catch (e) {
        console.log(e);
      }
    },
    // 格式化时间
    handleFormatDate(date) {
      // 格式化时间为 HH:MM:SS
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var seconds = date.getSeconds(); // 判断是不是小于10 返回01 02 03
      function check(num) {
        if (num < 10) {
          // 如果数字小于10,前边拼接个0
          return "0" + num;
        } else {
          return num;
        }
      }
      let sfm = check(hours) + ":" + check(minutes) + ":" + check(seconds);
      this.curTime = `${year}-${month}-${day} ${sfm}`;
      return sfm;
    },
    handleClick(index) {
      this.activIndex = index;
    },
    changeMap() {
      const { lng, lat } = this.$route.query;
      if (lng && lat) {
        this.$router.push({
          path: "/attendanceMap",
          query: {
            lat: lat,
            lng: lng,
          },
        });
      } else {
        this.$router.push({
          path: "/attendanceMap",
          query: {
            lat: this.curlatitude,
            lng: this.curlongitude,
          },
        });
      }
    },
    handleDate(index) {
      this.dataIndex = index;
    },
    handleLeave() {
      this.leaveShow = true;
    },
    handleCancel() {
      this.leaveShow = false;
    },
    handleSure() {
      this.leaveShow = false;
    },
    // 打卡
    async handleDk() {
       try {
        let params = {
          pos: this.curlongitude + ',' + this.curlatitude,
          addr: this.address,
          inout: this.clockStatus
        }
        const {code} = await httpService.post("/api/staff/clockio", {...params});
        if (code === 0) {
          Toast("打卡成功");
          this.checkTodayTime();
        }
      } catch (e) {
        console.log(e);
      }

    }
  },
  destroyed() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="less">
.attendance-warp {
  background: rgb(248, 248, 248);
  letter-spacing: 0px;
  position: relative;
  .d-around {
    justify-content: space-around !important;
  }
  .img-yuandian {
    width: 10px;
    height: 10px;
    object-fit: cover;
  }
  .path9-img {
    margin-left: 16px;
    margin-top: 15px;
    width: 16px;
    height: 14px;
    object-fit: cover;
  }
  .clock-box {
    height: 300px;
    margin-top: 48px;
    padding-top: 42px;
    .clock-content {
      width: calc(100vw - 190px);
      height: 186px;
      border-radius: 50%;
      /* #3A86FF */
      background: rgb(58, 134, 255);
      /* 凸 */
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .ask-leaveButton {
      width: 186px;
      height: 44px;
      background: rgb(58, 134, 255);
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.3);
      border-radius: 3px;
      text-align: center;
      line-height: 44px;
      color: #fff;
      margin: 0 auto;
      margin-top: -10px;
    }
  }
  .ask-leaveBox {
    width: calc(100vw - 56px);
    height: 300px;
    /* 白色 */
    background: rgb(255, 255, 255);
    border-radius: 10px 10px 0px 0px;
    margin: 0 auto;
    margin-top: 48px;
    .leave-title {
      color: rgb(34, 34, 47);
      font-family: Noto Sans SC;
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0px;
      text-align: center;
      padding-top: 18px;
    }
    .drop-menu {
      border: 0.5px solid rgb(34, 34, 47);
      border-radius: 5px;
      margin: 18px 21px 10px 21px;
      .van-dropdown-menu__title {
        width: 90%;
      }
      .van-dropdown-menu__title::after {
        opacity: 1 !important;
        border: 6px solid;
        top: 40% !important;
        border-color: transparent transparent #000 #000;
      }
    }
    .leave-textarea {
      border: 0.5px solid rgb(34, 34, 47);
      border-radius: 5px;
      width: calc(100vw - 98px);
      height: 106px;
      margin: 0 auto;
    }
    .leave-flexButton {
      width: 90%;
      margin: 0 auto;
      margin-top: 17px;
      .leave-button {
        display: inline-block;
        width: 50%;
        height: 56px;
        line-height: 56px;
        text-align: center;
        background: rgb(255, 255, 255);
        border-radius: 0px 0px 0px 12px;
      }
      .leave-button-border {
        border-top: 1px solid #ccc;
        border-right: 1px solid #ccc;
      }
      .leave-button-borderight {
        border-top: 1px solid #ccc;
      }
    }
  }
  .clock-addr {
    display: flex;
    justify-content: center;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 16px;
    .img-addr {
      width: 16px;
      height: 16px;
      object-fit: cover;
    }
  }
  .warp-btn {
    display: flex;
    margin-top: 25px;
    justify-content: center;
    .van-button {
      width: 145px;
      width: 146px;
      font-size: 12px;
      border-radius: 3px;
      padding: 0 !important;
    }
  }
}
</style>
