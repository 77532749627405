<template>
  <div>
    <div class="date-bottom-warp date-dk-wrap">
      <div class="date-item blue kq-item">
        <div class="bg-blur"></div>
        <div class="date-kq-wrap">
          <div class="d-f a-center">
            <img
              class="img-kqperson"
              :src="require('@/assets/image/kqperson.png')"
              alt=""
            />
            <span class="p-l10">{{userInfo.name}}</span>
          </div>
          <div class="date-kqwrap-right">
            <span
              class="kq-span"
              :class="dataIndex == index ? 'kq-span-active' : 'kq-span'"
              v-for="(item, index) in dateList"
              :key="index"
              @click="handleDate(index)"
              >{{ item }}</span
            >
          </div>
        </div>
        <!-- <div class="date-kq-bottom">
          <div>
            <span class="f-s14 c-76 b-dashed">2022</span>
            <span class="c-76 m-l8">|</span>
            <span class="f-s14 m-l8 c-76 b-dashed">08.02</span>
          </div>
          <div>
            <span class="date-kq-button" @click="goToday" v-if="dataIndex === 0"
              >回到今天</span
            >
            <span class="date-kq-button" @click="goToday" v-if="dataIndex === 1"
              >回到本周</span
            >
            <span class="date-kq-button" @click="goToday" v-if="dataIndex === 2"
              >回到本月</span
            >
          </div>
        </div> -->
      </div>
    </div>
    <!-- 回到今天 -->
    <div
      class="calendar-wrapToday flexone"
      v-if="dataIndex === 0 && !detailShow"
    >
      <!-- <van-calendar
        :poppable="false"
        :show-confirm="false"
        :show-title="false"
        :show-subtitle="true"
        :max-date="maxDate"
        :min-date="minDate"
        :default-date="defaultDate"
        row-height="50"
        :style="{ height: hideShow ? '300px' : '200px' }"
      />
      <img
        class="img-zkss"
        :src="
          hideShow
            ? require('@/assets/image/ss.png')
            : require('@/assets/image/zk.png')
        "
        alt=""
        @click="handleHideShow"
      /> -->
      <div class="f-s12 dk-title">
        出勤统计：打卡{{
          Number(formData.in_count) + Number(formData.out_count)
        }}次，上班打卡{{ formData.in_count }}次，下班打卡{{
          formData.out_count
        }}次，
      </div>
      <!-- <van-steps
        direction="vertical"
        :active="0"
        active-color="rgb(34, 34, 47)"
      >
        <van-step>
          <template #inactive-icon>
            <div class="active-icon"></div>
          </template>
          <template #active-icon>
            <div class="active-icon"></div>
          </template>
          <template #finish-icon>
            <div class="active-icon"></div>
          </template>
          <p class="f-s14 c-34">上班 08:18:18</p>
          <p class="c-gray f-s12 d-f-base">
            <img
              class="img-dw"
              :src="require('@/assets/image/dw.png')"
              alt=""
            />
            <span class="d-block p-l6 pd-10"
              >江苏省南通市通州区刘桥镇739号104室江苏省南通市通州区刘桥镇739号104室江苏省南通市通州区
            </span>
          </p>
        </van-step>
        <van-step>
          <p class="f-s14 c-34">上班 08:18:18</p>
          <p class="c-gray f-s12 d-f-base">
            <img
              class="img-dw"
              :src="require('@/assets/image/dw.png')"
              alt=""
            />
            <span class="d-block p-l6 pd-10"
              >江苏省南通市通州区刘桥镇739号104室</span
            >
          </p>
        </van-step>
      </van-steps> -->
      <!-- <div class="f-s12 p-l16">统计截止至 2022-08-01 20:20</div> -->
    </div>
    <!-- 回到本周 -->
    <div
      class="calendar-wrapWeek flexone"
      v-if="dataIndex === 1 && !detailShow"
    >
      <!-- <div class="week-box">
        <van-tabs v-model="weekActive" scrollspy sticky>
          <van-tab
            v-for="(item, index) in weekList"
            :key="index"
            :title="'第' + item + '周'"
          >
          </van-tab>
        </van-tabs>
      </div>
      <div class="f-s12">
        <van-grid :column-num="3" :border="false">
          <van-grid-item>
            <p>111</p>
            <p>平均工时</p>
          </van-grid-item>
          <van-grid-item>
            <p>111</p>
            <p>出勤天数</p>
          </van-grid-item>
          <van-grid-item>
            <p>111</p>
            <p>出勤班次</p>
          </van-grid-item>
        </van-grid>
        <van-grid :column-num="3" :border="false">
          <van-grid-item>
            <p>111</p>
            <p>迟到次数</p>
          </van-grid-item>
          <van-grid-item>
            <p>111</p>
            <p>早退次数</p>
          </van-grid-item>
          <van-grid-item>
            <p>111</p>
            <p>缺卡次数</p>
          </van-grid-item>
        </van-grid>
        <van-grid :column-num="3" :border="false">
          <van-grid-item>
            <p>111</p>
            <p>请假天数</p>
          </van-grid-item>
          <van-grid-item>
            <p>111</p>
            <p>矿工天数</p>
          </van-grid-item>
        </van-grid>
      </div> -->
      <div class="f-s12 dk-title">
        出勤统计：打卡{{
          Number(formData.in_count) + Number(formData.out_count)
        }}次，上班打卡{{ formData.in_count }}次，下班打卡{{
          formData.out_count
        }}次，
      </div>
      <!-- <div class="f-s12 p-l16 pd-10">统计截止至 2022-08-01 20:20</div> -->
      <!-- <van-divider
        :style="{
          color: 'rgb(196, 196, 196)',
          borderColor: 'rgb(196, 196, 196)',
          padding: '0 16px',
        }"
      />
      <p class="f-s12 t-c pd-10 pb-20" @click="handelDetail('week')">
        查看详情
      </p> -->
    </div>
    <!-- 回到本月 -->
    <div
      class="calendar-wrapWeek flexone"
      v-if="dataIndex === 2 && !detailShow"
    >
      <!-- <div class="week-box">
        <van-tabs v-model="monthActive" scrollspy sticky>
          <van-tab
            v-for="(item, index) in monthList"
            :key="index"
            :title="item + '月'"
          >
          </van-tab>
        </van-tabs>
      </div>
      <div class="f-s12">
        <van-grid :column-num="3" :border="false">
          <van-grid-item>
            <p>111</p>
            <p>平均工时</p>
          </van-grid-item>
          <van-grid-item>
            <p>111</p>
            <p>出勤天数</p>
          </van-grid-item>
          <van-grid-item>
            <p>111</p>
            <p>出勤班次</p>
          </van-grid-item>
        </van-grid>
        <van-grid :column-num="3" :border="false">
          <van-grid-item>
            <p>111</p>
            <p>迟到次数</p>
          </van-grid-item>
          <van-grid-item>
            <p>111</p>
            <p>早退次数</p>
          </van-grid-item>
          <van-grid-item>
            <p>111</p>
            <p>缺卡次数</p>
          </van-grid-item>
        </van-grid>
        <van-grid :column-num="3" :border="false">
          <van-grid-item>
            <p>111</p>
            <p>请假天数</p>
          </van-grid-item>
          <van-grid-item>
            <p>111</p>
            <p>矿工天数</p>
          </van-grid-item>
        </van-grid>
      </div> -->
      <div class="f-s12 dk-title">
        出勤统计：打卡{{
          Number(formData.in_count) + Number(formData.out_count)
        }}次，上班打卡{{ formData.in_count }}次，下班打卡{{
          formData.out_count
        }}次，
      </div>
      <!-- <div class="f-s12 p-l16 pd-10">统计截止至 2022-08-01 20:20</div> -->
      <!-- <van-divider
        :style="{
          color: 'rgb(196, 196, 196)',
          borderColor: 'rgb(196, 196, 196)',
          padding: '0 16px',
        }"
      />
      <p class="f-s12 t-c pd-10 pb-20" @click="handelDetail('week')">
        查看详情
      </p> -->
    </div>
    <!-- 本周或者本月查看详情 -->
    <div class="calendar-wrapDetail flexone" v-if="detailShow">
      <van-collapse v-model="activeName" accordion :border="false">
        <van-collapse-item name="1" :border="false">
          <template #title>
            <div>
              <span class="vandetail-title">平均工时</span
              ><span class="span-bg"></span
              ><span class="vandetail-info">8小时</span>
            </div>
          </template>
          <van-cell-group>
            <van-cell
              title="2022-05-25（星期日）"
              value="8小时"
              title-class="vanCell"
            />
            <van-cell
              title="2022-05-25（星期日）"
              value="8小时"
              title-class="vanCell"
            />
            <van-cell
              title="2022-05-25（星期日）"
              value="8小时"
              title-class="vanCell"
            />
            <van-cell
              title="2022-05-25（星期日）"
              value="8小时"
              title-class="vanCell"
            />
          </van-cell-group>
        </van-collapse-item>
        <van-collapse-item name="2" :border="false">
          <template #title>
            <div>
              <span class="vandetail-title">出勤天数</span
              ><span class="span-bg"></span
              ><span class="vandetail-info">5天</span>
            </div>
          </template>
          内容
        </van-collapse-item>
        <van-collapse-item name="3" :border="false">
          <template #title>
            <div>
              <span class="vandetail-title">出勤班次</span
              ><span class="span-bg"></span
              ><span class="vandetail-info">5天</span>
            </div>
          </template>
          内容
        </van-collapse-item>
        <van-collapse-item name="4" :border="false">
          <template #title>
            <div>
              <span class="vandetail-title">迟到次数</span
              ><span class="span-bg"></span
              ><span class="vandetail-info">5次</span>
            </div>
          </template>
          内容
        </van-collapse-item>
        <van-collapse-item name="5" :border="false">
          <template #title>
            <div>
              <span class="vandetail-title">早退次数</span
              ><span class="span-bg"></span
              ><span class="vandetail-info">5次</span>
            </div>
          </template>
          内容
        </van-collapse-item>

        <van-collapse-item name="6" :border="false">
          <template #title>
            <div>
              <span class="vandetail-title">缺卡次数</span
              ><span class="span-bg"></span
              ><span class="vandetail-info">5次</span>
            </div>
          </template>
          内容
        </van-collapse-item>
        <van-collapse-item name="7" :border="false">
          <template #title>
            <div>
              <span class="vandetail-title">请假天数</span
              ><span class="span-bg"></span
              ><span class="vandetail-info">5天</span>
            </div>
          </template>
          内容
        </van-collapse-item>
        <van-collapse-item name="8" :border="false">
          <template #title>
            <div>
              <span class="vandetail-title">矿工天数</span
              ><span class="span-bg"></span
              ><span class="vandetail-info">5天</span>
            </div>
          </template>
          内容
        </van-collapse-item>
      </van-collapse>
    </div>
  </div>
</template>
<script>
import httpService from "@/plugins/http";
import mixin from "@/mixin/dateMixin";
import { mapState } from "vuex";
export default {
  mixins: [mixin],
  computed: {
    ...mapState(["userInfo"]),
  },
  data() {
    return {
      dataIndex: 0,
      dateList: ["日", "周", "月"],
      currentDate: new Date(),
      defaultDate: new Date(),
      minDate: new Date(new Date().getFullYear(), 0, 0),
      maxDate: new Date(),
      hideShow: false,
      weekList: ["一", "二", "三", "四"],
      weekActive: 0,
      monthList: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "10",
        "11",
        "12",
      ],
      monthActive: 0,
      detailShow: false,
      activeName: "1",
      formData: {
        in_count: null, //上班打卡次数
        out_count: null, //下班打卡次数
      },
    };
  },
  mounted() {
    this.currentMonth();
    this.checkTodayTime();
  },
  methods: {
    async getInit(params) {
      try {
        this.$store.commit("showLoading");
        const res = await httpService.post("/api/staff/clockio/stats", {
          begin: params.begin,
          end: params.end,
        });
        console.log(res, "treer");
        if (res.code === 0) {
          this.formData = res.data || {};
        } else {
          this.$store.commit("hideLoading");
        }
      } catch (error) {
        console.log(error, "error");
      } finally {
        this.$store.commit("hideLoading");
      }
    },
    currentMonth() {
      //只允许选择当前月
      let dateLimit = new Date();
      let year = dateLimit.getFullYear();
      let month = dateLimit.getMonth() + 1;
      // let totalDate = dateLimit.getDate();
      let d = new Date(year, month, 0);
      let enddate = d.getDate();
      console.log(month, enddate, "enddate");
      this.minDate = new Date(year, month - 1, 1);
      this.maxDate = new Date(year, month - 1, enddate);
      console.log(this.minDate, this.maxDate);
    },
    goToday() {
      this.defaultDate = new Date();
    },
    handleDate(index) {
      this.detailShow = false;
      this.dataIndex = index;
      if (index === 0) {
        //日
        this.checkTodayTime();
      } else if (index === 1) {
        //周
        this.checkWeekTime();
      } else if (index === 2) {
        //月
        this.checkMonthTime();
      }
    },
    handleHideShow() {
      this.hideShow = !this.hideShow;
    },
    handelDetail(val) {
      if (val == "week") {
        this.detailShow = true;
      }
    },
  },
};
</script>
<style lang="less">
.img-kqperson {
  width: 30px;
  height: 30px;
  object-fit: cover;
}
.dk-title {
  padding-top: 20px;
  padding-left: 16px;
  padding-bottom: 10px;
}
.date-dk-wrap {
  display: block !important;
  height: 96px !important;
  .kq-item {
    display: block !important;
    height: 96px !important;
  }
}
.date-kq-wrap {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px 0 16px;
  width: 100%;
  align-items: center;
  .date-kqwrap-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 96px;
    height: 26px;
    /* 中灰 */
    background: rgb(225, 229, 229);
    border-radius: 2px;
    padding: 0 3px;
    .kq-span {
      width: 26px;
      height: 22px;
      line-height: 22px;
      text-align: center;
      border-radius: 2px;
      display: inline-block;
    }
    .kq-span-active {
      background: rgb(255, 255, 255);
    }
  }
}
.date-kq-bottom {
  display: flex;
  justify-content: space-between;
  padding: 20px 16px;
  width: 100%;
  align-items: center;
  .date-kq-button {
    width: 70px;
    height: 22px;
    line-height: 22px;
    text-align: center;
    /* #3A86FF */
    background: rgb(58, 134, 255);
    border-radius: 3px;
    color: #fff;
    display: inline-block;
  }
}
.b-dashed {
  border: 1px dashed rgb(76, 76, 76);
  border-radius: 2px;
  padding: 0 1px;
}
.calendar-wrapToday {
  background: rgb(255, 255, 255);
  margin: 8px 15px 0 15px;
  border-radius: 5px;
  height: calc(100vh - 300px);
  .van-calendar__header {
    box-shadow: 0 0 0 !important;
    .van-calendar__header-subtitle {
      display: none;
    }
  }
  .van-calendar__selected-day {
    width: 30px;
    height: 30px;
    background: #3a86ff;
    border-radius: 50%;
  }
  .img-zkss {
    width: 100%;
    object-fit: cover;
  }

  .img-dw {
    width: 9px;
    height: 12px;
    object-fit: cover;
  }
  .active-icon,
  .van-step__circle {
    width: 12px;
    height: 12px;
    background: rgb(196, 196, 196);
    border-radius: 50%;
  }
}
.calendar-wrapWeek {
  background: rgb(255, 255, 255);
  margin: 8px 15px 0 15px;
  border-radius: 5px;
  height: calc(100vh - 300px);
  .week-box {
    margin-top: 20px;
    .van-tabs {
      margin: 0 15px;
    }
    .van-tab {
      width: 65px;
      height: 26px;
      border-radius: 10px;
      display: inline-block;
      text-align: center;
      line-height: 26px;
      font-size: 12px;
    }
    .van-tab--active {
      background: rgb(58, 134, 255);
      color: #fff;
    }
    .van-tabs__line {
      display: none;
    }
    .van-tabs--line .van-tabs__wrap {
      height: 30px;
    }
  }
}
.calendar-wrapDetail {
  .van-collapse {
    .van-collapse-item {
      .van-cell {
        background: rgb(248, 248, 248);
      }
      .van-collapse-item__wrapper {
        .van-collapse-item__content {
          .van-cell-group {
            .van-cell {
              background: #fff !important;
            }
          }
        }
      }
    }
  }
  .vanCell {
    > span {
      display: inline-block;
      width: 240px;
    }
  }
  .van-collapse {
    .van-collapse-item {
      margin-bottom: 5px;
      .van-cell--clickable {
        display: flex;
        align-items: center;
        line-height: 40px;
        background: rgb(255, 255, 255);
        border-radius: 3px;
        margin: 0 15px;
        width: auto;
        padding: 0;
      }
      .van-collapse-item__content {
        margin: 0 15px;
        border-radius: 3px;
      }
    }
  }
  .vandetail-title {
    display: inline-block;
    width: 85px;
    text-align: center;
    background: rgb(255, 255, 255);
    border-radius: 3px;
  }
  .span-bg {
    background: rgb(248, 248, 248);
    height: 40px;
    width: 6px;
    display: inline-block;
    vertical-align: top;
  }
  .vandetail-info {
    display: inline-block;
    background: rgb(255, 255, 255);
    margin-left: 2px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
}
</style>
