export default {
  computed: {},
  data() {
    return {
      forms: {
        begin: "",
        end: "",
      },
    };
  },
  methods: {
    // 当日
    checkTodayTime() {
      // 添加本周时间
      this.forms.begin = this.formatDate(new Date()) + " " + "00:00:00"; // 本周起始时间
      // 添加本周最后一天时间
      this.forms.end = this.formatDate(new Date()) + " " + "23:59:59"; // 本周终止时间
      console.log(this.forms, 1)
      this.getInit(this.forms);
    },
    // 本周
    getCurrentDate() {
      // 获取当前时间
      return new Date();
    },
    formatDate(date) {
      console.log(date, '[year, month, day].join("-")[year, month, day].join("-")')
      // 格式化时间为yyyy-mm-dd
      var d = new Date(date);
      var month = "" + (d.getMonth() + 1);
      var day = "" + d.getDate();
      var year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      return [year, month, day].join("-");
    },
    checkWeekTime() {
      // 获取当前时间
      var currentDate = this.getCurrentDate();
      // 返回date是一周中的某一天
      var week = currentDate.getDay();
      // 返回date是一个月中的某一天
      // var month = currentDate.getDate()
      // 一天的毫秒数
      var millisecond = 1000 * 60 * 60 * 24;
      // 减去的天数
      var minusDay = week !== 0 ? week - 1 : 6;
      // alert(minusDay);
      // 本周 周一
      var monday = new Date(currentDate.getTime() - minusDay * millisecond);
      // 本周 周日
      var sunday = new Date(monday.getTime() + 6 * millisecond);
      console.log(this.formatDate(monday), this.formatDate(sunday));
      // 添加本周时间
      this.forms.begin = this.formatDate(monday) + " " + "00:00:00"; // 本周起始时间
      // 添加本周最后一天时间
      this.forms.end = this.formatDate(sunday) + " " + "23:59:59"; // 本周终止时间
      this.getInit(this.forms);
    },
    // 本月
    checkMonthTime() {
      // 获取当前时间
      var currentDate = this.getCurrentDate();
      // 获得当前月份0-11
      var currentMonth = currentDate.getMonth();
      // 获得当前年份4位年
      var currentYear = currentDate.getFullYear();
      // 求出本月第一天
      var firstDay = new Date(currentYear, currentMonth, 1);

      // 当为12月的时候年份需要加1
      // 月份需要更新为0 也就是下一年的第一个月
      if (currentMonth === 11) {
        currentYear++;
        currentMonth = 0; // 就为
      } else {
        // 否则只是月份增加,以便求的下一月的第一天
        currentMonth++;
      }
      // 一天的毫秒数
      var millisecond = 1000 * 60 * 60 * 24;
      // 下月的第一天
      var nextMonthDayOne = new Date(currentYear, currentMonth, 1);
      // 求出上月的最后一天
      var lastDay = new Date(nextMonthDayOne.getTime() - millisecond);

      this.forms.begin = this.formatDate(firstDay) + " " + "00:00:00"; // 本月起始时间
      // 添加本周最后一天时间
      this.forms.end = this.formatDate(lastDay) + " " + "23:59:59"; // 本月终止时间
      this.getInit(this.forms);
    },
  },
  mounted() { },
};
